import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import "./FixedButton.scss";

function FixedButton({ isHomePage }) {
  const [scrollTop, setScrollTop] = useState(0);
  const [fadeMin, setFadeMin] = useState(0);
  {
    {
      useEffect(() => {
        if (isHomePage) {
          let boundingRect = document
            .getElementById("contact")
            .getBoundingClientRect();
          setFadeMin(boundingRect.bottom + document.documentElement.scrollTop);
        }
      }, []);

      useEffect(() => {
        const onScroll = (e) => {
          setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
      }, [scrollTop]);
    }
    const calcOffset = (val) => {
      return  parseInt((val - window.innerHeight / 2).toFixed());
    };

    if (isHomePage) {
      if (scrollTop >= 464 && scrollTop < calcOffset(fadeMin)) {
        return (
          <div className="fixed-button">
            <Link to="#contact" offset={10}>
              <button type="button">Contactanos</button>
            </Link>
          </div>
        );
      }
    } else {
      if (scrollTop > 0) {
        return (
          <div className="fixed-button">
            <Link to="/#contact" offset={10}>
              <button type="button">Contactanos</button>
            </Link>
          </div>
        );
      }
    }
  }

  return null;
}

export default FixedButton;
